<template>
  <ak-container card :bodyStyle="{ padding: 0 }" class="ak-card-table">
    <PageHeader></PageHeader>
    <a-tabs v-model="activeKey" @change="tabChange">
      <a-tab-pane key="1" :tab="`创作者平台${contentName}管理`">
      </a-tab-pane>
      <a-tab-pane key="3" :tab="`商家平台${contentName}管理`">
      </a-tab-pane>
      <a-tab-pane key="2" :tab="`独立店${contentName}管理`">
      </a-tab-pane>
    </a-tabs>
    <ArticleListTable ref="child" :contentType="contentType"/>
  </ak-container>
</template>

<script>
  import ArticleListTable from './article-list-table'

  export default {
    name: 'ArticleListComp',
    components: {ArticleListTable},
    dictTypes: [
      {
        dictType: 'contentType',
        dataType: Number
      },
      {
        dictType: 'platformType',
        dataType: Number
      }
    ],
    data() {
      return {
        activeKey: '1', // 业务平台类型，独立店，商户，创作者
      }
    },
    computed: {
      // 内容：作品，文章
      contentName() {
        return this.dict.label.contentType[this.contentType]
      }
    },
    props: {
      contentType: Number | String
    },
    methods: {
      // 拖动结束，松开鼠标
      onDrop(sourceItem, targetItem, isDrop) {
        console.log(sourceItem, targetItem, isDrop)
      },
      confirm() {
        this.$confirm({
          icon: 'exclamation-circle',
          title: '是否删除该信息？',
          content: '删除该标签将会解除，动态与标签的关系，请谨慎操作',
          onOk: () => {
          },
          onCancel: () => {
          },
          class: 'grounding-modal'
        })
      },
      onSearch(searchValue) {
        console.log('use value', searchValue)
      },

      tabChange(key) {
        let param = {
          contentType: this.contentType,
          businessType: Number(key)
        };
        console.log(param)
        this.$refs.child.tabChange(param);
      }
    }
  }
</script>
<style lang="less" scoped>
  /deep/ .ant-card-body {
    padding: 18px 18px 8px 18px;
  }

  /deep/ .ant-col-xl-8 {
    padding-left: 0 !important;
  }
</style>
